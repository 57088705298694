import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import deepEqual from 'lodash.isequal'
import leaflet from 'leaflet'

import { pointListBounds } from './utils'
import { mapResetSuccess, mapSetZoom } from './redux/actions'
import { theme, Map } from 'issy-components'
import { getMostRecentUserPosition } from './redux/selectors'
import { usePrevious } from './hooks'
import config from './config'

const MAP_FIT_BOUNDS_PADDING = [10, 10]
const MAP_BOUNDS = [
    theme.map.defaultMaxBounds[0],
    [theme.map.defaultMaxBounds[1][0] + 0.1, theme.map.defaultMaxBounds[1][1]]
]
const PATHS_STYLE = { color: theme.colors.Ocean, weight: 2 }

const createPaths = (leafletMap, paths) => {
    Object.entries(paths).forEach(([_, element]) => {
        const polyline = leaflet.polyline(element.path, element.style || PATHS_STYLE)
        polyline.addTo(leafletMap)
    })
}

const MapWithPaths = ({ mapPaths, minZoom, maxZoom, ...props }) => {
    const [leafletMap, setLeafletMap] = useState(null)
    const dispatch = useDispatch()
    const resetRequested = useSelector((state) => state.map.resetRequested)
    const userPosition = useSelector(getMostRecentUserPosition)
    const previousUserPosition = usePrevious(userPosition)

    const _fitBounds = (leafletMap) => {
        let bounds = pointListBounds(theme.map.issyBounds)
        if (userPosition) {
            bounds = pointListBounds([...bounds, userPosition])
        }
        leafletMap.fitBounds(bounds, { padding: MAP_FIT_BOUNDS_PADDING })
    }

    useEffect(() => {
        dispatch(mapSetZoom(config.MAP_INITIAL_ZOOM))
    }, [])

    useEffect(() => {
        if (leafletMap && mapPaths) {
            createPaths(leafletMap, mapPaths)
        }
    }, [leafletMap && mapPaths])

    useEffect(() => {
        if (!leafletMap) {
            return
        }
        if (resetRequested || !deepEqual(userPosition, previousUserPosition)) {
            _fitBounds(leafletMap)
            dispatch(mapResetSuccess())
        }
    }, [dispatch, resetRequested, userPosition, leafletMap])

    const onLeafletMapCreated = (leafletMap) => {
        setLeafletMap(leafletMap)
        setTimeout(() => _fitBounds(leafletMap), 50)
    }

    return (
        <div>
            <Map
                {...props}
                minZoom={minZoom}
                maxZoom={maxZoom}
                maxBounds={MAP_BOUNDS}
                onLeafletMapCreated={onLeafletMapCreated}
            />
        </div>
    )
}

export default MapWithPaths
