import React, { useEffect } from 'react'
import {
    theme,
    MapShapes,
    MapInfoBoxContent1,
    MapInfoBoxContent2,
    MapInfoBoxContent4,
    MapInfoBoxContentIcon,
    MapInfoBoxContentLabel,
    TwoColumnPageWithMap
} from 'issy-components'

import PageLeftContent from './PageLeftContent'
import Header from './Header'
import iconLocation from './assets/issy-green-awesomefont_icones/issy-green-awesomefont_icone-parc_localisation.svg'
import iconCalendar from './assets/issy-green-awesomefont_icones/issy-green-awesomefont_icone-parc_horaires.svg'
import iconJeux from './assets/issy-green-awesomefont_icones/issy-green-awesomefont_icone-airedejeux.svg'
import iconToilets from './assets/issy-green-awesomefont_icones/issy-green-awesomefont_icone-parc_toilettes.svg'
import iconFountain from './assets/issy-green-awesomefont_icones/issy-green-awesomefont_icone-parc_fontaine.svg'
import iconPicNic from './assets/issy-green-awesomefont_icones/issy-green-awesomefont_icone-parc_piquenique.svg'
import { mapSetShapes, mapResetShapes } from './redux/actions'
import config from './config'
import { useSelector, useDispatch } from 'react-redux'

const MapInfoBoxContent = ({ mapSelectedShape }) => {
    if (!mapSelectedShape) {
        return null
    }
    return (
        <div>
            <MapInfoBoxContent1>{mapSelectedShape.nom}</MapInfoBoxContent1>

            {mapSelectedShape.localisation ? (
                <MapInfoBoxContent2>
                    <MapInfoBoxContentIcon src={iconLocation} alt='' />
                    <MapInfoBoxContentLabel>
                        {mapSelectedShape.localisation}
                    </MapInfoBoxContentLabel>
                </MapInfoBoxContent2>
            ) : null}

            {mapSelectedShape.horaires ? (
                <MapInfoBoxContent4>
                    <MapInfoBoxContentIcon src={iconCalendar} alt='' />
                    <MapInfoBoxContentLabel>
                        {mapSelectedShape.horaires}
                    </MapInfoBoxContentLabel>
                </MapInfoBoxContent4>
            ) : null}

            {mapSelectedShape.sanitaires ? (
                <MapInfoBoxContent4>
                    <MapInfoBoxContentIcon src={iconToilets} alt='' />
                    <MapInfoBoxContentLabel>
                        Sanitaires publiques
                    </MapInfoBoxContentLabel>
                </MapInfoBoxContent4>
            ) : null}

            {mapSelectedShape.fontaine ? (
                <MapInfoBoxContent4>
                    <MapInfoBoxContentIcon src={iconFountain} alt='' />
                    <MapInfoBoxContentLabel>
                        Borne fontaine disponible
                    </MapInfoBoxContentLabel>
                </MapInfoBoxContent4>
            ) : null}

            {mapSelectedShape.airejeu ? (
                <MapInfoBoxContent4>
                    <MapInfoBoxContentIcon src={iconJeux} alt='' />
                    <MapInfoBoxContentLabel>
                        Aire de jeux
                    </MapInfoBoxContentLabel>
                </MapInfoBoxContent4>
            ) : null}

            {mapSelectedShape.piquenique ? (
                <MapInfoBoxContent4>
                    <MapInfoBoxContentIcon src={iconPicNic} alt='' />
                    <MapInfoBoxContentLabel>
                        Aire de pique-nique
                    </MapInfoBoxContentLabel>
                </MapInfoBoxContent4>
            ) : null}
        </div>
    )
}

const RightContent = ({
    shapes,
    mapSelectedShapeIndex,
    mapSelectedShape,
    solutionData
}) => {
    let infoBoxContent = null
    if (mapSelectedShape) {
        infoBoxContent = (
            <MapInfoBoxContent mapSelectedShape={mapSelectedShape} />
        )
    }

    if (shapes) {
        shapes = shapes.map((shape) => ({
            ...shape,
            styles: {
                default: {
                    color: theme.colors.GardenLight
                },
                selected: {
                    color: theme.colors.Ocean
                },
                over: {
                    color: theme.colors.Ocean
                }
            }
        }))
    }

    return (
        <MapShapes
            shapes={shapes}
            infoBoxContent={infoBoxContent}
            callToAction={solutionData.callToAction}
            urlSeeData={solutionData.urlSeeData}
            selectedShapeIndex={mapSelectedShapeIndex}
            maxZoom={config.MAP_MAX_ZOOM}
            minZoom={config.MAP_MIN_ZOOM}
        />
    )
}

const PageParcs = ({ solutionData, appData, pageConfig }) => {
    const dispatch = useDispatch()
    const mapSelectedShapeIndex = useSelector(
        (state) => state.map.selectedShapeIndex
    )
    const mapShapes = useSelector((state) => state.map.shapes)
    const mapSelectedShape =
        mapSelectedShapeIndex !== null ? mapShapes[mapSelectedShapeIndex] : null

    useEffect(() => {
        dispatch(mapSetShapes(appData[solutionData.name]))
        return () => dispatch(mapResetShapes())
    }, [])

    const rightContent = (
        <RightContent
            shapes={mapShapes}
            mapSelectedShapeIndex={mapSelectedShapeIndex}
            mapSelectedShape={mapSelectedShape}
            solutionData={solutionData}
        />
    )

    const leftContent = (
        <PageLeftContent
            textIntro={solutionData.textIntro}
            didYouKnow={solutionData.didYouKnow}
        />
    )

    return (
        <TwoColumnPageWithMap
            header={<Header />}
            leftContent={leftContent}
            rightContent={rightContent}
            rootUrl={config.ROOT_URL}
            titleImageUrl={pageConfig.pageTitleIcon}
            title={solutionData.title}
            lineColor={theme.colors.OceanLight}
        />
    )
}

export default PageParcs
