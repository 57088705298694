import React from 'react'
import styled from 'styled-components'

import Header from './Header'
import { HomeStartItem, theme } from 'issy-components'
import { withAppData } from './hoc'
import { FooterCredits } from 'issy-components'
import config from './config'
import pageConfigs from './page-configs'
import { UnderlinedWord } from 'issy-components'
import { HomePage } from 'issy-components'
import { HomeTitle } from 'issy-components'

const StartItemsContainer = styled.div`
    text-align: center;
    margin: auto;
    width: 50em;
    padding-bottom: 3em;

    @media (max-width: ${theme.devices.phablet}px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        width: 30em;
    }

    @media (max-width: ${theme.devices.mobile}px) {
        grid-template-columns: repeat(2, 1fr);
        width: 18em;
    }
`

const HomeStartItemReduced = styled(HomeStartItem)`
    ${HomeStartItem.components._Icon} {
        width: 105%;
    }

    @media (max-width: ${theme.devices.mobile}px) {
        /* Reduce title size because some titles are too long */
        ${HomeStartItem.components._Title} {
            font-size: 95%;
        }
    }
`

const Home = ({ appData }) => {
    const startItemsElems = appData.solution.map((solutionData) => {
        const pageId = solutionData.name
        const pageConfig = pageConfigs[pageId]
        if (!pageConfig) {
            console.error(`"${pageId}" not found in page configs`)
            return null
        }
        return (
            <HomeStartItemReduced
                title={solutionData.title}
                imgSrc={pageConfig.homeStartItemIcon}
                url={pageConfig.url}
                key={pageId}
            />
        )
    })

    return (
        <HomePage header={<Header />}>
            <HomeTitle>
                {' '}
                Je <UnderlinedWord text={'veux'} lineColor={theme.colors.GardenLight} /> ...
            </HomeTitle>
            <StartItemsContainer>{startItemsElems}</StartItemsContainer>
            <FooterCredits
                issyDataUrl={config.ISSY_DATA_URL}
                wedodataUrl={config.WEDODATA_URL}
            />
        </HomePage>
    )
}

export default withAppData(Home, ['solution'])
